import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'Zayn',
    useCase: 'UX, AE, ART DIRECTION',
    description: `I had the pleasure to Art Direct ZAYN for a shoot with GQ. We wanted to show him in a different light than past shoots. Tropical, airy, colorful with a twist of vintage rock.`,
  },
  caseId: 'zayn',
  contents: ['image0.jpg', 'image1.jpg', 'image2.jpg'],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
